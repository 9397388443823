import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';

const HomePage = () => {
    return (
        <>
            <Hero />
            <Features />
            { /* Add more components here */ }
        </>
    );
};

const App = () => {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                <Header />
                <main className="flex-grow">
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route path="/privacy" component={PrivacyPolicy} />
                    </Switch>
                </main>
                <Footer />
            </div>
        </Router>
    );
};

export default App;