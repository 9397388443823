import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-6 py-12 max-w-3xl">
            <h1 className="text-3xl font-bold mb-8">mDefender Privacy Policy</h1>
            
            <p className="mb-4 italic">Last updated: March 20, 2025</p>
            
            <p className="mb-8">This Privacy Policy outlines how your privacy is protected when using mDefender.</p>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Your Privacy Comes First</h2>
                <p className="mb-4">
                    mDefender is designed with privacy as its core principle. We are committed to ensuring that your data remains secure and private.
                </p>
            </section>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Information Collection and Use</h2>
                
                <h3 className="text-xl font-medium mb-3">Personal Data</h3>
                <p className="mb-4">
                    mDefender <strong>does not collect, store, or share any personal data</strong>. All message analysis is performed entirely on your device. Your messages never leave your iPhone.
                </p>
                
                <h3 className="text-xl font-medium mb-3">Message Content</h3>
                <p className="mb-4">
                    Your messages remain solely on your device. No content from your messages is ever sent to, stored in, or analyzed by external servers or cloud services.
                </p>
            </section>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">In-App Analytics and Tracking</h2>
                <p className="mb-4">
                    mDefender <strong>does not track user activity</strong> and does not use analytics or telemetry frameworks. Your usage of the app remains private and confidential.
                </p>
            </section>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Subscription and Payment Information</h2>
                <p className="mb-4">
                    All subscription and payment transactions are handled securely by Apple through your Apple ID. mDefender does not access, collect, or store any payment details.
                </p>
            </section>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Family Sharing</h2>
                <p className="mb-4">
                    mDefender supports Apple's Family Sharing feature, allowing subscription benefits to be shared among family members through Apple's secure infrastructure. No additional user data is collected or shared by mDefender.
                </p>
            </section>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Security</h2>
                <p className="mb-4">
                    Your security is important to us. mDefender uses industry-standard measures to protect the integrity of the app, ensuring your data remains secure on your device.
                </p>
            </section>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Changes to This Privacy Policy</h2>
                <p className="mb-4">
                    We may update our Privacy Policy occasionally. Any updates will be clearly communicated through the App Store page or within the app.
                </p>
            </section>
            
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                <p className="mb-4">
                    If you have any questions or concerns about our Privacy Policy or practices, please contact us:
                </p>
                <ul className="list-disc pl-6 mb-4">
                    <li className="mb-2">Email: contact@magiland.ca</li>
                    <li className="mb-2">Website: https://mdefender.magiland.ca</li>
                </ul>
                <p className="mt-6">Thank you for choosing mDefender.</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy; 