import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';

const Header = () => {
    return (
        <header className="bg-white shadow">
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <Link to="/" className="text-xl font-bold text-gray-800 hover:text-blue-600 transition">mDefender</Link>
                <Button text="Download on the App Store"/>
            </div>
        </header>
    );
};

export default Header;
